<template>
  <div class="invoice-component text-center">
    <div class="background-image">
      <img
        src="@/assets/RASHA-EL-SHERBINY-1.596c15af923ad068216c.jpg"
        alt="background"
      >
    </div>
    <div class="container content">
      <div class="image">
        <img src="@/assets/logo.svg">
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="border d-flex">
            <p>
              Date
            </p>
            <p class="border-left">
              {{ itemDetails.receipt_date }}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="border d-flex">
            <p>
              Branch
            </p>
            <p class="border-left">
              {{ itemDetails.branch.name }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="border d-flex">
            <p>
              Customer Name
            </p>
            <p class="border-left">
              {{ itemDetails.client.name }}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="border d-flex bb-0">
            <p>
              Item Code
            </p>
            <p>
              {{ itemDetails.products[0].label }}
            </p>
          </div>
          <div class="border d-flex bb-0">
            <p>
              Category
            </p>
            <p>
              {{ itemDetails.products[0].category.name }}
            </p>
          </div>
          <div class="border d-flex">
            <p>
              Gold Weight
            </p>
            <p>
              {{ itemDetails.products[0].gold_weight }}
            </p>
          </div>
          <div
            v-if="itemDetails.products[0].stones"
            class="tabel"
          >
            <h3 class="text-left pt-3">
              Diamond Details
            </h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="d-flex justify-space-between">
                    <th class="text-left">
                      No
                    </th>
                    <th class="text-left">
                      Label
                    </th>
                    <th class="text-left">
                      Qty
                    </th>
                    <th class="text-left">
                      Weight
                    </th>
                    <th class="text-left">
                      Color
                    </th>
                    <th class="text-left">
                      Clarity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="( item, i) in itemDetails.products[0].stones"
                    :key="item.id"
                    class="d-flex justify-space-between"
                  >
                    <td>{{ i+1 }}</td>
                    <td>{{ item.code ? item.code : '-' }}</td>
                    <td>{{ item.quantity ? item.quantity : '-' }}</td>
                    <td>{{ item.weight ? item.weight : '-' }}</td>
                    <td>{{ item.color ? item.color : '-' }}</td>
                    <td>{{ item.clarity ? item.clarity : '-' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <div class="col-md-6">
          <div class="product-image">
            <img
              :src="'https://api.newturquoisejewelry.com/img/products/'+ itemDetails.products[0].image"
              alt="product image"
            >
          </div>
        </div>
        <div class="col-12">
          <div class="border d-flex">
            <p>
              Total Price
            </p>
            <p class="border-left">
              {{ itemDetails.total_egp }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="border d-flex">
            <p>
              Paid Amount
            </p>
            <p class="border-left">
              {{ itemDetails.paid_egp }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="border d-flex">
            <p>
              Seller Name
            </p>
            <p class="border-left">
              {{ itemDetails.employee.name }}
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="border">
            <p class="text-left py-6">
              New Turquoise Authorised Signatory:
            </p>
          </div>
        </div>
        <div class="col-12 text-center">
          <div class="d-flex">
            <p>
              Cairo:
            </p>
            <p>
              Building S4, Downtown Mall. New Cairo - 01005529552
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    created () {
      console.log('itemDetails', this.itemDetails)
    },
  }
</script>
<style lang="scss">
.text-center{
  text-align: center;
}
.invoice-component{
margin: auto;
.background-image{
  img{
    width: 800px;
  }
}
.pt-3{
  padding-top: 15px;
}
.py-6{
  padding: 15px 0;
}
.text-center{
  text-align: center;
}
.d-flex{
  display: flex
}
.text-left{
  text-align: left;
}
.content{
  position: absolute;
  top: 60px;
  left: 0px;
  padding: 0 75px;
  margin: auto;
  width: 100%;
}
.justify-space-between{
  justify-content: space-between;
}
.v-data-table{
  background-color: transparent;
  border: 1px solid #000;
  tr{
    th, td{
      padding: 0 5px !important;
      height: auto !important;
      text-align: center !important;
    }
  }
}
  .border{
    border: 1px solid #000;
    p{
      margin-bottom: 0 !important;
      padding: 10px;
    }
    .border-left{
      border-left: 1px solid #000;
    }
  }
  .bb-0{
    border-bottom: unset;
  }
  .product-image{
    width: 100%;
    img{
      width: 100%;
      height: 290px;
    }
  }
}
</style>
