<template>
  <div>
    <h3 class="py-3">
      Filter by :
    </h3>
    <v-row>
      <v-col md="3">
        <v-text-field
          v-model="form.receipt_number"
          outlined
          dense
          :label="$t('filter.invoiceNumber')"
          @input="doFilter()"
        />
      </v-col>
      <v-col md="3">
        <v-autocomplete
          v-model="form.client_name"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          outlined
          dense
          item-text="name"
          item-value="name"
          :label="$t('filter.clients')"
          :placeholder="$t('filter.startTypingToSearch')"
          @input="doFilter()"
        />
      </v-col>
      <v-col md="3">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date_from"
              label="Date From"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.date_from"
            @input="menu = false, doFilter()"
          />
        </v-menu>
      </v-col>
      <v-col md="3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date_to"
              label="Date To"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.date_to"
            @input="menu2 = false, doFilter()"
          />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const clientsService = ServiceFactory.get('clients')

  export default {
    data: (vm) => ({
      descriptionLimit: 30,
      search: null,
      isLoading: false,
      entries: [],
      model: null,
      clients: [],
      sallesList: [],
      menu: false,
      menu2: false,
      form: {
        client_name: null,
        receipt_number: null,
        date_from: null,
        date_to: null,
      },
    }),
    computed: {
      items () {
        return this.entries.map(entry => {
          const name = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name
          return Object.assign({}, entry, { name })
        })
      },
    },

    watch: {
      async search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        const listData = await clientsService.getActiveList()
        this.entries = listData
        this.count = listData.length
        this.isLoading = false
      },
    },
    created () {
    },
    methods: {
      doFilter () {
        this.$emit('doFilter', this.form)
      },
    },
  }
</script>
